<template>
  <v-navigation-drawer :permanent="$vuetify.breakpoint.mdAndUp" :expand-on-hover="$vuetify.breakpoint.mdAndUp" id="default-drawer" v-model="drawer" :dark="dark" :right="$vuetify.rtl" :src="drawerImage ? image : ''" :mini-variant.sync="mini" mini-variant-width="80" app width="260">
    <template v-if="drawerImage" #img="props">
      <v-img :key="image" :gradient="gradient" v-bind="props" />
    </template>
    <!-- testata del menù e voci di menù -->
    <div class="px-2">
      <v-img class="ma-4" :src="require('@/assets/pittogramma-negativo.svg')" v-if="mini" />
      <default-drawer-header v-else />
      <v-divider class="mx-3 mb-2" />
      <enesi-list :mini="mini"/>
      <default-list :items="items" />
    </div>
    <!-- FINE SEZIONE -->
    <!-- messaggio di errore se browser != chrome -->
    <v-alert v-if="showAlertChrome && !mini">
      <v-row align="center">
        <v-col class="grow" style="text-align:center;">
          We recommend using Google Chrome
        </v-col>
      </v-row>
      <v-btn class="mt-5" block @click="$window.open('https://www.google.com/intl/it_it/chrome/', '_blank')">DOWNLOAD</v-btn>
    </v-alert>
    <div class="pa-4 text-center" v-if="showAlertChrome && !!mini">
      <app-btn block class="text-none" color="error" href="#" v-on:click="$window.open('https://www.google.com/intl/it_it/chrome/', '_blank')">
        <v-icon> mdi-alert-outline </v-icon>
      </app-btn>
    </div>
    <!-- FINE SEZIONE -->
    <!-- LOGOUT -->
    <template v-if="mini" #append>
      <div class="pa-4 text-center">
        <app-btn block class="text-none" color="tertiary" href="#" v-on:click="logout">
          <v-icon > mdi-arrow-collapse-left </v-icon>
        </app-btn>
      </div>
    </template>
    <template v-else #append>
      <div>
        <div class="pa-4 text-center">
          <app-btn block class="text-none" color="tertiary" href="#" v-on:click="logout">
            <v-icon left> mdi-arrow-collapse-left </v-icon>
            <span>Logout</span>
          </app-btn>
        </div>
      </div>
    </template>
    <!-- FINE SEZIONE -->
    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { get, sync, call } from "vuex-pathify";

export default {
  drawer: true,
  name: "DefaultDrawer",
  mini: true,
  mounted() {
    this.mini= true
  },
  methods: {
    logout: function () {
      localStorage.removeItem('tok1');
      localStorage.removeItem('tok2');
      this.$keycloak.logout();
    },
  },
  components: {
    DefaultDrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        "./widgets/DrawerHeader"
      ),
    DefaultList: () =>
      import(
        /* webpackChunkName: "default-list" */
        "./List"
      ),
    EnesiList: () =>
      import(
        /* webpackChunkName: "enesi-list" */
        "./EnesiList"
      ),
    ...call("orders", ["initOrderDraftCollection"])
  },
  computed: {
    pullDown: sync("utils/pullDown"),
    ...get("user", ["dark", "gradient", "image"]),
    ...get("app", ["items", "version"]),
    ...sync("app", ["drawer", "drawerImage", "mini"]),
    showAlertChrome: function() {
      return !this.$window.chrome;
    }
  },
};
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
  
</style>
